import React, { useEffect, useState } from 'react';
import { Row, Col, Switch } from 'antd'
import { other } from '../../common/data/defautKeys.ts';
import DefineTime from '../../common/component/defineTime/defineTime.tsx'
import { OtherSlider } from '../../common/component/slider/slider.tsx';
import KeyBoard from '../keys/keyBoard.tsx';
import { useSelector, useDispatch } from 'react-redux';
import { funFormat } from '../../common/request/format.ts'
import { setTyfun } from '../../store/kdevice/imp.ts'
import { setTySetting } from '../..//store/setting/imp.ts'
import { tySendReport } from '../agreement.ts';
import { debounce } from '../../common/global.ts';

type keysType = {
    key: string,
    text: string, 
    component: string
}

const returnComponent = (
    {key, text, component}:keysType,
    advancedData:any,
    t:Function,
    handClick:Function) => {
    // console.log('key', key, '--------------------')
    if (component === 'switch') {
        const checked:boolean = advancedData[key]?true:false;
        // console.log(advancedData[key])
        return <Col span={12} key={key}>
            <span className='label'>{t(text)}</span>
            <Switch checked={checked} onChange={(bl) => handClick(bl?'0X01':'0X00')}/>
        </Col>;
    } else if (component === 'defineTime') {
        let result:number = 0;
        for (let adkey in advancedData) {
            if (adkey === `${key}_L`) result += advancedData[adkey];
            else if (adkey === `${key}_H`) result += advancedData[adkey]*16*16;
        }
        // console.log(result)
        return <Col span={12} key={key}>
            <span className='label'>{t(text)}</span>
            <DefineTime data={result/60} onClick = {(data:any) => handClick(data)} />
        </Col>;
    } else if (component === 'otherSlider') {
        // console.log(advancedData[key])
        return <Col span={12} key={key}>
            <span className='label'>{t(text)}</span>
            <OtherSlider />
        </Col>;
    } else return null;
}
const Advanced = ({t}:any) => {
    const { setting } = useSelector((state:any) => state.kdevice);
    const {checkedProFile} = useSelector((state:any) => state.settingReducer);
    const [advancedData, setAdvancedData] = useState({});
    const dispatch = useDispatch();
    /**
     * 
     * @param key 点击的按钮类 
     * @param data data 按钮参数
     */
    const handClick = async (key:string, data:any) => {
        // 获取当前修改 选项的 坐标
        const index = funFormat.findIndex((type) => type.includes(key));

        let newarr:any = [...setting];
        // 数据设置
        if (Object.prototype.toString.call(data) === '[object Array]') {
            newarr[index] = data[0];
            newarr[index+1] = data[1];
        } else {
            newarr[index] = data;
        }

        debounce('tySendReport', async () => {
            const result:any = await tySendReport(newarr);
            if (result && result?.length) {
                dispatch(setTyfun(result));
                dispatch(setTySetting(result))
            }
        }, 100)
    }

    useEffect(() => {
        let param:any = {};
        funFormat.forEach((key:string, i:number) => {
            // console.log(key, nowdata[i])
            param[key] = parseInt(setting[i], 16);
        });
        setAdvancedData(param);

    }, [setting, checkedProFile]);

    return <div className='other' >
            <div className='board'>
                <KeyBoard t={t} />
            </div> 
            <div className='other-body'>
                <Row gutter={[80, 16]} style={{height: '35vh', padding: `15px 30px`, overflowY:'scroll'}}>
                {
                    other.map((item:keysType) =>{
                        return returnComponent(
                            item,
                            advancedData,
                            t,
                            (data:string|string[]) => handClick(item.key, data))
                    } )
                }
                </Row>
            </div>
    </div>
}

Advanced.displayName = 'Advanced';
export default Advanced;
