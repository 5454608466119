import Type from './type.ts'

export let ndevice:any = null;
export let reqcount:number = 0;

let deviceSettting = {
    productName: "productName",
    productId: "",
    _productId: 1,
    vendorId: "",
    proFileId: "",
    valid_data_length: 64,
    key_rect_size: 256,
    hong_space_size: 2048,
    setting: [], // 通用信息
    default_key_Rect: [],
    key_Rect: [],
    hong_data: [],
    light_rect: [],
}

// export const testConnect = async () => {
//     const bol:boolean = hid && hid?.canConnect ? await hid?.canConnect() : false;
//     return {
//         type: Type.connectDevice,
//         connect: bol,
//         device: null,
//     }
// }

export const removeDevices = () => {
    return {
        type: Type.connectDevice,
        connect: false,
        device: null,
    }

}

// 更新呢 通用信息
export const setTyfun = (setting:string[]) => {
    return {
        type: Type.setTyfun,
        setting
    }
}

// 更新键盘信息 
export const updateKeyRect = (arr:any) => {
    return {
        type: Type.setRectKey,
        key_Rect: arr
    }
}

export const install = (data:any) => {
    // 设备信息获取完成后数据初始化
    return {
        type: Type.install,
        deviceSettting: {...deviceSettting, ...data}
    }
}

// 存储自定义键盘灯光
export const installLightRect = (light_Rect:string[]|number[]) => {
    // 设备信息获取完成后数据初始化
    return {
        type: Type.installLightRect,
        light_Rect
    }
}
// 更新定义键盘灯光
export const updateLightRect = (light_Rect:string[]|number[]) => {
    // 设备信息获取完成后数据初始化
    return {
        type: Type.updateLightRect,
        light_Rect
    }
}

