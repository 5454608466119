import { applyMiddleware, combineReducers, legacy_createStore } from 'redux';
import { thunk } from 'redux-thunk';
import settingReducer from './setting/index.ts';
import kdevice from './kdevice/kdevice.ts';
import hong from './hong/index.ts'

const reducer:any = combineReducers({
    kdevice,
    settingReducer,
    hong
});
 
const makeStore = legacy_createStore(reducer, applyMiddleware(thunk));

export default makeStore;