class HidRequest{
    device:any;
    requesting:boolean = false;
    requestGroup: any[] = [];
    nowCommd:number|null = null;
    devicefilter = [];
    reqLen = 63;
    
    constructor(filterd) {
        this.device = null;
        this.requesting = false;
        this.devicefilter = filterd;
        
    }
    setReqLen(reqLen:number) {
        this.reqLen = reqLen-1;
    }
    getReqLen() {
        return this.reqLen;
    }
    getpoorDevices() { 
       return new Promise(async (resolve, reject) => {
            try {
                const poordevices:any = await (navigator as any).hid.getDevices();
                resolve({
                    status: "ok",
                    poordevices
                })
            } catch (error) {
                reject({
                    status: "fail",
                    error
                });
            }
                
       });
    }   
    async getDevice() {
        const devices:any = await (navigator as any).hid.requestDevice({
            filters: [...this.devicefilter],
        });
        const poordevices:any = await (navigator as any).hid.getDevices();
        if (poordevices && poordevices?.length > 0) {
            const d = poordevices.filter((item:any) => item?.productId === devices[0]?.productId);
            if (d && d?.length === 1) {
                this.device = d[0];
            } else if (d && d?.length > 1) {
                d.forEach((ele:any) => {
                    if (ele && ele?.collections && ele?.collections?.length > 1) {
                        this.device = ele;
                    }
                });
            } else {
                this.device = devices[0];
            }
            
        }  else {
            this.device = devices[0];
        }
        return this.device;
    }   
 
    canRequest() {
        return !!this.device;
    }
    async canConnect() {
        const devices:any = await (navigator as any).hid.getDevices();
        return devices && devices?.length ? true : false;
    }
    // async request(data:any) {
    //     try {
    //         if (!this.device) {
    //             alert('请连接设备');
    //             throw "Device is null";
    //         } else if (this.device && !this.device?.opened) {
    //             await this.device.open();
    //         } else if (!this.device?.opened) {
    //             throw "Device not opened";
    //         }
    
    //         if (this.requesting) {
    //             this.requestGroup.push(data);
    //             console.log("this.requestGroup.push(data)", data)
    //         } else {    
    //             const buffer:Uint8Array = new Uint8Array(this.reqLen);
    //             data?.forEach((ele:string|number, i:number) => {
    //                 buffer[i] = parseInt(ele+"", 16);
    //             });
    //             this.nowCommd = buffer[0];
    //             this.device.sendReport(6, buffer);
    //         }
    //         this.requesting = true;
    //     } catch (e) {
    //         console.log('报错', e)
    //     }
    // }
    request(reqdata:any) {
        const _device = this.device;
        const reqLen = this.reqLen;
        return new Promise(async function (resolve, reject):Promise<any> {
            try {
                if (!_device) {
                    alert('请连接设备');
                    throw "Device is null";
                } else if (_device && !_device?.opened) {
                    await _device.open();
                } else if (!_device?.opened) {
                    throw "Device not opened";
                }
                // 数据转换 number 转 Uint8Array
                const buffer:Uint8Array = new Uint8Array(reqLen);
                reqdata?.forEach((ele:string|number, i:number) => {
                    buffer[i] = parseInt(ele+"", 16);
                });

                // 监听当前设备返回
                _device.addEventListener("inputreport", function doreq(event:any) {
                    const { data, reportId } = event;
                    if (reportId !== 6 && reportId !== 7) return;

                    // 返回数据初始化
                    let result:number[] = [];
                    let len = data?.byteLength;
                    let i = 0;
                    while (i < len) {
                        result[i] = data.getUint8(i);
                        i++;
                    }
                    console.log("返回结果 data?.byteLength", result)
                    if (buffer[0] === result[0]) {
                        resolve({
                            status: "ok",
                            result,
                        });
                        _device.removeEventListener("inputreport", doreq);
                    }

                });
            
                console.log("发送结果 reqdata?.length", reqdata)
                _device.sendReport(6, buffer); // 发送协议
            } catch (error) {
                reject({
                    status: "fail",
                    error
                })
            }
        });
    }

    callback(commd:number) {
        if (commd !== this.nowCommd) return;
        if (!this.requestGroup || !this.requestGroup?.length) {
            this.requesting = false;
        } else {
            const [frist, ...arr] = this.requestGroup;
            try {
                if (!this.device) {
                    alert('请连接设备');
                    throw "Device is null";
                } else if (this.device && !this.device?.opened) {
                    this.device.open();
                } else if (!this.device?.opened) {
                    throw "Device not opened";
                }  
                this.requestGroup = arr;

                const buffer:Uint8Array = new Uint8Array(this.reqLen);
                frist?.forEach((ele:string|number, i:number) => {
                    buffer[i] = parseInt(ele+"", 16);
                });
                this.nowCommd = buffer[0];
                this.device?.sendReport(6, buffer);
                this.requesting = true; 
            } catch (e) {
                console.log('报错', e)
            }
        }
    }
}

export default HidRequest;