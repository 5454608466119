import {Command, CommandWay, getDataWay} from '../common/request/txFormat.ts';
import {calcTime, tenTo16} from '../common/global.ts';
import HidRequest from '../common/request/hidRequest.ts';
import products from '../common/data/product.ts';
import { keyTypeNum } from '../common/data/defautKeys.ts';

let running:boolean = false;
let hid:HidRequest;
export const getDevices = async () => {
    running = true;
    if (!(navigator as any).hid) {
        console.log('当前环境不支持navigator.hid', navigator);
        alert(`当前环境不支持navigator.hid`);
        return;
    }
    hid = new HidRequest(products.getByName(['productId', "vendorId"])); // 获取支持的产品信息 查询但钱usb列表 进行设备连接
    const _device = await hid.getDevice(); // 设备连接
    const reqlen:number = products.getRequstDataLen(_device.productId)
    Command.set(`${_device.productId}`); // 根据产品id 获取当前产品的基本信息
    hid.setReqLen(reqlen); // 设置当前产品设备的 请求字段长度是 32、64
    running = false;
    return {
        productId: _device.productId,
        productName: _device.productName,
        vendorId: _device.vendorId,
        is32: reqlen === 32,
        device: _device,
    }
}
// 读取设备信息
export const readDeviceMessage = async () => {
    running = true;
    const newData = CommandWay(Command?.get('getDeviceMessage'), [], ["00", "00"], hid?.getReqLen());
    const {status, result}:any = await hid.request(newData);
    running = false;
    if (status === 'ok') {
        return getDataWay(tenTo16(result[0]), result, {}, hid?.getReqLen())?.data;
    } else {
        return null;
    }
}

// 读取通用信息
export const readTyMessage = async () => {
    running = true;
    const newData = CommandWay(Command?.get('getdData'), [], ["00", "00"], hid?.getReqLen());
    const {status, result}:any = await hid.request(newData);
    running = false;
    if (status === 'ok') {
        return getDataWay(tenTo16(result[0]), result, {}, hid?.getReqLen()).data;
    } else {
        return null;
    }
}
//写通用信息
export const tySendReport = async(data:any = []) => {
    running = true;
    const datalen = hid?.getReqLen() - 7;
    const sendCount = Math.ceil(64 / datalen);
    let tag:boolean = true;

    let tysetting:any = [];
    tysetting.length = 64;
    tysetting.fill(0);

    for (let i = 0; i < sendCount; i++) {
        let ndata:string[] = [];;
        const nowIndex = i*datalen;
        for (let j = 0; j < datalen; j++) {
            if ((nowIndex + j) < 64) ndata.push(data[nowIndex + j]);
        }

        const _place = calcTime(i*datalen);
        const defaultData = CommandWay(Command?.get('setData'), [...ndata], [..._place], hid?.getReqLen());
        const {status}:any = await hid.request(defaultData);

        if (status !== 'ok') {
            tag = false;
            break;
        }
    }

    running = false;
    return tag ? data:null; 
}

// 读取默认按键信息
export const readDefaultKeyMessage = async (key_rect_size:number) => {
    running = true;
    const datalen = hid?.getReqLen() - 7; // 获取当前设备有效长度 
    const sendCount = Math.ceil(key_rect_size / datalen); // 计算发送协议的次数 

    const default_key_rect:any = [];
    default_key_rect.length = key_rect_size;
    default_key_rect.fill(0);
    // 分段请求
    for (let i = 0; i < sendCount; i++) {
        // 读取 第 i个 默认按键信息
        const _place = calcTime(i*datalen);
        const defaultData = CommandWay(Command?.get('getKeyboard'), [], [..._place], hid?.getReqLen());
        const {status, result}:any = await hid.request(defaultData);
        if (status === 'ok') {
            const start = i*datalen;
            for (let x = 0; x < datalen; x++) {
                if (start+x < key_rect_size) default_key_rect[start+x] = result[7+x];
            }
        }
    }
    running = false;
    return default_key_rect;
}

// 读取当前按键信息
export const readKeyMessage = async (key_rect_size:number) => {
    running = true;
    const datalen = hid?.getReqLen() - 7; // 获取当前设备有效长度 
    const sendCount = Math.ceil(key_rect_size / datalen); // 计算发送协议的次数
 
    let key_rect:any = [];
    key_rect.length = key_rect_size;
    key_rect.fill(0);

    // 分段请求
    for (let i = 0; i < sendCount; i++) {
        // 读取 第 i个 按键信息
        const _place = calcTime(i*datalen);
        const defaultData = CommandWay(Command?.get('getkeyMessage'), [], [..._place], hid?.getReqLen());
        const {status, result}:any = await hid.request(defaultData);
        if (status === 'ok') {
            const start = i*datalen;
            for (let x = 0; x < datalen; x++) {
                if (start+x < key_rect_size) key_rect[start+x] = result[7+x];
            }
        }
    }
    running = false;
    return  key_rect;
}

//改键
export const saveRectKey = async(data:number[] = []) => {
    running = true;
    const datalen = hid?.getReqLen() - 7;
    const sendCount = Math.ceil(data?.length / datalen);

    let key_rect:any = [];
    key_rect.length = data?.length;
    key_rect.fill(0);

    for (let i = 0; i < sendCount; i++) {
        const ndata:string[] = [];;
        const nowIndex = i*datalen;
        for (let j = 0; j < datalen; j++) ndata.push(tenTo16(data[nowIndex + j]));
        
        const _place = calcTime(i*datalen);
        const defaultData = CommandWay(Command?.get('setkeyMessage'), ndata, [..._place], hid?.getReqLen());
        const {status, result}:any = await hid.request(defaultData);

        if (status === 'ok') {
            const start = i*datalen;
            for (let x = 0; x < datalen; x++) {
                if (start+x < data?.length) key_rect[start+x] = result[7+x];
            }
        }
    }
    running = false;
    return  key_rect;  
}

// 读取自定义灯光信息
export const readLightRect = async (key_rect_size:number) => {
    running = true;
    const datalen = hid?.getReqLen() - 7;
    const sendCount = Math.ceil(key_rect_size / datalen);

    let light_Rect:any = [];
    light_Rect.length = key_rect_size;
    light_Rect.fill(0);

    for (let i = 0; i < sendCount; i++) {
        const _place = calcTime(i*datalen);
        const defaultData = CommandWay(Command?.get('getlightMessage'), [], [..._place], hid?.getReqLen());
        const {status, result}:any = await hid.request(defaultData);
        if (status === 'ok') {
            const start = i*datalen;
            for (let x = 0; x < datalen; x++) {
                if (start+x < key_rect_size) light_Rect[start+x] = result[7+x];
            }
        }
    }
    running = false;
    return light_Rect;
}
// 修改自定义灯光
// 设置自定义灯光信息
export const setLightRect = async(data:number[] = []) => {
    running = true;
    const datalen = hid?.getReqLen() - 7;
    const sendCount = Math.ceil(data?.length / datalen);

    let light_Rect:any = [];
    light_Rect.length = data?.length;
    light_Rect.fill(0);

    for (let i = 0; i < sendCount; i++) {
        let ndata:string[] = [];;
        const nowIndex = i*datalen;
        for (let j = 0; j < datalen; j++) ndata.push(tenTo16(data[nowIndex + j]));
        
        const _place = calcTime(i*datalen);
        const defaultData = CommandWay(Command?.get('setlightMessage'), ndata, [..._place], hid?.getReqLen());
        const {status, result}:any = await hid.request(defaultData);
        if (status === 'ok') {
            const start = i*datalen;
            for (let x = 0; x < datalen; x++) {
                if (start+x < data?.length) light_Rect[start+x] = result[7+x];
            }
        }
    }
    running = false;
    return light_Rect;
}

// 检查2.4G连接
export const checkConnect = async () => {
    if (running) return "running";
    running = true;
    try {
        const defaultData = CommandWay(Command?.get('checkConnect'), [], ["00", "00"], hid?.getReqLen());
        const resp:any = await hid?.request(defaultData);
        running = false;
        if (resp&&resp?.status === 'ok') {
            return getDataWay(tenTo16(resp?.result[0]), resp?.result, {}, hid?.getReqLen());
        }
        console.log(resp?.result, 'result')
    } catch (e) {
        console.log(e)
    }
    
}

// 读取电池状态(电量、充电)
export const readPoor = async () => {
    if (running) return "running";
    running = true;
    try {
        const defaultData = CommandWay(Command?.get('getPoorNum'), [], ["00", "00"], hid?.getReqLen());
        const resp:any = await hid?.request(defaultData);
        running = false;
        if (resp && resp?.status === 'ok') {
            return getDataWay(tenTo16(resp?.result[0]), resp?.result, {}, hid?.getReqLen());
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
} 

//profile 恢复默认
export const restAll = async () => {
    running = true;
    const defaultData = CommandWay(Command?.get('restKeyBoard'), [], ["00", "00"], hid?.getReqLen());
    const resp = await hid?.request(defaultData);
    running = false;
    return resp;
} 

/**
 * 保存宏
 * @param settings  宏数字
 * @param size 宏空间大小
 * @returns 
 */
export const deviceSave = async (settings:any = [], size:number):Promise<boolean> => {
    running = true;
    // --------------------------------------------------------数据处理模块
    let tag = true;
    // 当前有效协议长度
    const datalen = hid?.getReqLen() - 7;

    // 计算宏个数 settings的数组长度
    let hongLen:string[] = calcTime(settings.length);

    // 声明宏容器 自动填满  填充 前八段协议
    let hong_Rect:any = [];
    hong_Rect.length = size;
    hong_Rect.fill('0x00');
    hong_Rect[0] = "0xaa";
    hong_Rect[1] = "0x55";
    hong_Rect[4] = hongLen[0];
    hong_Rect[5] = hongLen[1];
    
    // 计算当前有数据坐标
    let newIndex = 8;

    // 按键 code
    const codeValue:any = keyTypeNum.getCodeValue();

    // 保留宏地址填写位置 大小： 宏个数 * 2
    newIndex += settings.length*2;

    // 遍历宏列表
    for (const key in settings) {
        // 当前循环中计算宏的地址
        const len = calcTime(newIndex);
        // 判断当前选中的宏文件是否有 按键配置
        if (settings[key]?.option && settings[key]?.option?.length) {

            // 填写宏地址
            hong_Rect[8+(Number(key||'0')*2)] = len[0]; // 宏地址低字节
            hong_Rect[8+(Number(key||'0')*2)+1] = len[1]; //宏地址高字节

            // 动作数量 占位 4 宏0动作数量L、宏0动作数量H、保留、保留
            const _num:string[] = calcTime(settings[key]?.option?.length);
            hong_Rect[newIndex] = _num[0];
            hong_Rect[newIndex+1] = _num[1];

            //跳过动作数量 坐标
            newIndex += 4;

            // 遍历单个红文件 操作按键的个数
            for (const item of settings[key]?.option) {
                // 动作数据 4 
                let _time = calcTime(item.time);
                hong_Rect[newIndex] = _time[0];
                hong_Rect[newIndex+1] = _time[1];
                
                // 获取按键值
                const code = codeValue[item.key] && codeValue[item.key]?.[2] !== '0x00' ?
                    codeValue[item.key]?.[2] : codeValue[item.key]?.[1];
                hong_Rect[newIndex+2] = code;
                // 操作code
                hong_Rect[newIndex+3] = item.option === "down"? "0x81":"0x01";
                //跳过动作 坐标
                newIndex += 4;
            }
        }
    }
    hong_Rect[2] = calcTime(hong_Rect.length)[0];
    hong_Rect[3] = calcTime(hong_Rect.length)[1];

    // --------------------------------------------------------数据发送模块

    // 计算报文发送次数
    const reqCount = Math.ceil(size / datalen);
    for (let i = 0; i < reqCount; i++) {
        //按顺序发送协议
        let ndata:string[] = [];;
        const nowIndex = i*datalen;
        for (let j = 0; j < datalen; j++) ndata.push(hong_Rect[nowIndex + j]);

        const _place = calcTime(i*datalen);
        const defaultData = CommandWay(Command?.get('sethongMessage'), ndata, [..._place], datalen+7);
        const {status}:any = await hid.request(defaultData);
        if (status !== 'ok') {
            tag = false;
            break;
        } 
    }
    running = false;
    return tag;
}

