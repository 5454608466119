import {useState} from 'react';
// import { useRoutes } from 'react-router';
// 导入默认语言资源
import en from './en.json';
 
// 获取当前语言的资源，或默认资源
const getMessages = (locale:string) => {
  try {
    const messages = require(`../locales/${locale}.json`);
    return messages;
  } catch (error) {
    return en;
  }
};
 
export const useTranslation = () => {
  const [locale, setLocale] = useState<string>('en');
  // const router = useRoutes();
 
  // 获取当前语言的翻译文本
  const t = (key:string) => getMessages(locale)[key];
 
  // 切换语言并重新加载页面
  const changeLocale = (newLocale:string) => {
    setLocale(newLocale);
    // router.push(router.asPath, router.asPath, { locale: newLocale });
  };
 
  return { t, locale, changeLocale };
};
