import React, { useEffect, useState } from 'react';
import { ThunderboltOutlined } from '@ant-design/icons';
import {
    readPoor
   } from "../../../pages/agreement.ts";

let req:any;
export const Poor = () => {
    const [message, setMessage] = useState({
        poor: 0, //电量值
        poorStatus: 0 // 0未充电 1充电中 2已充满
    })
    
    useEffect(() => {
        async function queryPoor() {
            const resp:any = await readPoor();
            if (resp === "running") return;
            if (resp && resp?.status && resp?.data) {
                setMessage({
                    poor: resp?.data?.poor || 0, //电量值
                    poorStatus: resp?.data?.poorStatus || 0 // 0未充电 1充电中 2已充满
                })
            }
        }
        queryPoor();
        clearInterval(req);
        req = setInterval(queryPoor, 8000);
    }, [message])
    return <div className='poor'>
        <div className='tag'></div>
        <div className='show' style={{width: message && message?.poor ? Math.round(message?.poor * 26 * 0.01) : 0}}></div>
        <div className='poortextbox'>{`${message?.poor}%`}</div>
        {message && message?.poorStatus === 1 ? <ThunderboltOutlined className='poor-show'/>:null}
    </div>
}