import React from 'react';
import { Menu, Dropdown, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const returnMenu = (t: Function, click: Function, arr:any = [], ) => {
    return (
      <Menu>
        {
          arr&&arr?.length ? arr.map((str:any) => (
            <Menu.Item key={str} onClick={() => click(str)}>
              {str ? t(str):''}
            </Menu.Item>
          )):null
        }
      </Menu>
    )
 }
  

const CItem = (
    {
        name,
        key,
    }:any,
    handleClick:Function,
    t:Function,
    {
        showInput,
        setShowInput
    }:any,
    option:any,
    checked:boolean = false
):any => {
    
    return (
        <div className={`item_btn${checked?' checked':""}`}
            key={key}>
            <span style = {{cursor:'default'}} onClick={() => {
                handleClick('click');
            }}>
                {showInput !== key?
                    <span>
                    {String(name).substring(0, 15)}
                    </span>:
                    <Input style={{width: `120px`}} defaultValue={name}
                    onBlur={(e)=> {
                        e.preventDefault();
                        handleClick('edit', e?.target.value ? e?.target.value:name);
                        setShowInput('');
                    }}
                    onKeyDown={(e:any) => {
                        if (e?.keyCode !== 13) return;
                        handleClick('edit', e?.target?.value ? e?.target?.value:name);
                        setShowInput('');
                    }}
                    />
                }
            </span>
            <Dropdown
                overlay={returnMenu(t, (type:string) => {
                    if (type === 'edit') setShowInput(key);
                    else handleClick(type);
                    }, option)}
                    placement="bottomRight"
                >
                <RightOutlined />
            </Dropdown>
        </div>
    )
}
CItem.displayName = 'CItem';
export default CItem;
       
