import Type from "./type.ts";

const initialState = {
    connect: false,
    key_Rect: [],
    default_key_Rect: [],
    key_rect_size: 256,
    hong_space_size: 2048, 
    device: {
        name: '',
    },
    setting: [],
    light_Rect: [],
};

const kdevice = (state = initialState, action:any) => {
    switch (action.type) {
        case Type.connectDevice:
        return {
            ...state,
            connect: action?.connect,
            device: action.device
        };
        case Type.getDeviceMessage:
        return {
            ...state,
            count: state.connect,
        };
        case Type.setTyfun:
            console.log(action, 'action @@')
        return {
            ...state,
            setting: JSON.parse(JSON.stringify(action.setting)||JSON.stringify(state.setting)),
        };
        /** 初始化当前选中状态 */
        case Type.install:
            return {
                ...state,
                ...action?.deviceSettting
            };
        case Type.setRectKey:
            return {
                ...state,
                key_Rect: JSON.parse(JSON.stringify(action?.key_Rect))||[]
            }
        case Type.installLightRect:
            return {
                ...state,
                light_Rect: JSON.parse(JSON.stringify(action?.light_Rect)||JSON.stringify(state.light_Rect)),
            }
        case Type.updateLightRect:
            return {
                ...state,
                light_Rect: JSON.parse(JSON.stringify(action?.light_Rect)||JSON.stringify(state.light_Rect)),
            }
        default:
            return state;
    }   
};


export default kdevice;
