import Type from './type.ts';
import {exportHongJSON} from '../../common/global.ts';
import {deviceSave} from '../../pages/agreement.ts'

export const getSettings = (name:string) => {
    return {
        type: Type.getDatas,
        name,
    };
}

export const addHongName = (name:string) => {
    return {
        type: Type.addhongname,
        name,
    };
}

// export const getSetting = () => {
    
//     return {
        
//     }
// }

export const add = () => {
    return {
        type: Type.add,
    }
}

export const doImportSetting = (importsetting:any) => {  
    return {
        type: Type.Import,
        importsetting: JSON.parse(importsetting),
    }
}


export const edit = (key: string, value: string) => {
    return {
        type: Type.edit,
        key,
        value
    }
}

export const deleteone = (key: string) => {
    return {
        type: Type.delete,
        key
    }
}

export const copy = (key: string) => {  
    return {
        type: Type.copy,
        key
    }
}


export const exportFile = (data: string) => { 
    exportHongJSON(data, `Hong${new Date().getTime()}.json`)
}

export const saveDatas = async (settings = [], size:number) => {
    const result:boolean = await deviceSave(settings, size);
    if (result) {
        return {
            type: Type.save,
            settings,
        }
    } else {
        return false;
    }
    
}


// const deviceSave = async (settings:any = [], size:number, dataLen:number = 56) => {
//     let hongLen:string[] = calcTime(settings.length);
//     console.log(hongLen, settings.length, '---------');
//     let result:string[] = ["0xaa", "0x55", "0x00","0x00",hongLen[0], hongLen[1], "0x00","0x00"];
//     let newIndex = 8; 
//     let sizeIndex = 0;
//     const codeValue:any = keyTypeNum.getCodeValue();

//     for(let i = 0; i < settings.length*2; i++) result.push('0x00');
//     newIndex += settings.length*2;

//     for (const key in settings) {
//         const len = calcTime(newIndex);
//         if (settings[key]?.option && settings[key]?.option?.length) {
//             result[8+(Number(key||'0')*2)] = len[0]; // 宏地址低字节
//             result[8+(Number(key||'0')*2)+1] = len[1]; //宏地址高字节

//             // 动作数量
//             result.push(calcTime(settings[key]?.option?.length)[0]);
//             result.push(calcTime(settings[key]?.option?.length)[1]);
//             result.push('0x00');
//             result.push('0x00');
//             newIndex += 4;
//             for (const item of settings[key]?.option) {
//                 // 动作数据
//                 let time = calcTime(item.time); 
//                 result.push(time[0]);
//                 result.push(time[1]);
//                 const code = codeValue[item.key] && codeValue[item.key]?.[2] !== '0x00' ?
//                     codeValue[item.key]?.[2] : codeValue[item.key]?.[1];
//                 result.push(code);
//                 // alert(`${code}`)
//                 result.push(item.option === "down"? "0x81":"0x01");
//                 newIndex += 4;
//             }
//         }
//     }
//     result[2] = calcTime(result.length)[0];
//     result[3] = calcTime(result.length)[1];

//     sizeIndex = result?.length;
//     while(sizeIndex < size) {
//         result.push('0x00');
//         sizeIndex++;
//     }
//     // alert(`result.length: ${result?.length}, size: ${size}, result： ${result}`)
//     console.log('result', result, 'resul')

//     try {
//         if (!ndevice) {
//             alert('请连接设备');
//             // throw "Device is null";
//             return;
//         } else if (ndevice && !ndevice?.opened) {
//             await ndevice.open();
//         } else if (!ndevice?.opened) {
//             // throw "Device not opened";
//             return;
//         }

//         const reqCount = Math.ceil(result?.length / dataLen);
//         for (let i = 0; i < reqCount; i++) {
//             setTimeout(() => {
//                 console.log(`~~~~~~~~~~~~~~~~~~~~~~~${i} ~~~~~~~~~ ${i*dataLen+7} ~~~${(i+1) *dataLen}`)
//                 let ndata:string[] = [];;
//                 let j = i*dataLen;
//                 while(j >= i*dataLen && j < (i+1)*dataLen && j < size) {
//                     ndata.push(result[j]||'0x00');
//                     j++;
//                 }
//                 // alert(`ndata length ${ndata?.length}, i: ${i}`);
//                 const defaultData = CommandWay(Command?.get('sethongMessage'), ndata, calcTime(i*dataLen), dataLen+7);
//                 console.log(ndata, 'ndata', defaultData)
//                 console.log(`~~~~~~~~~~~~~~~~~~~~~~~${i} ~~~~~~~~~ ${i*dataLen} ~~~${(i+1) *(dataLen+7)}`)
//                 const buffer:Uint8Array = new Uint8Array(dataLen+7);

//                 defaultData?.forEach((ele:string|number, i:number) => {
//                     buffer[i] = parseInt(ele+"", 16);
//                 })
//                 ndevice.sendReport(6, buffer);
//             }, i * 30);
//         }
//     } catch (e) {
//         console.log('报错', e)  
//     }
    

// }