import Type from './type.ts'

const initialState:any = {
    settings: [],
    checkedKey: "",
    checkedProFile: "",
    productId: '',
    defaultsetting: {},
};

const setLocal = (id:string, settings:any) => {
    console.log(`id` ,id, `settings`, settings)
    localStorage.setItem(id, JSON.stringify(settings));
}
  
const settingStore = (state = initialState, action:any) => {
    let arr:any = state.settings;
    
    switch (action.type) {
        case Type.install:
            console.log(action?.productId, 'action', action?.defaultsetting_n)
        return {
            ...state,
            productId: action?.productId,
            checkedProFile: action.checkedProFile,
            settings: JSON.parse(JSON.stringify(action?.settings||[])),
            defaultsetting: {
                ...action?.defaultsetting_n
            }
        };
        case Type.add:
            console.log(state?.defaultsetting, 'state?.defaultsetting');
            arr.push({
                ...state?.defaultsetting,
                key: `${new Date().getTime()}${Math.floor(Math.random()*10)}`,
                name: `profile${arr?.length+1}`
            });
            setLocal(state?.productId, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.Import:
            arr.push({
                ...state?.defaultsetting,
                key: `${new Date().getTime()}${Math.floor(Math.random()*10)}`,
                ...action?.importsetting
            });
            setLocal(state?.productId, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.delete:
            arr = arr.filter((item:any) => action.key !== item.key);
            setLocal(state?.productId, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.edit:
            arr = arr.map((item:any) => {
                return {
                    ...item,
                    name: action.key === item.key ? action.value : item.name,
                }
            });
            setLocal(state?.productId, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.copy:
            const newarr = arr.find((item:any) => action.key === item.key);
            arr.push({
                ...newarr,
                key: `${new Date().getTime()}${Math.floor(Math.random()*10)}`,
                name: `${newarr?.name}_copy`
            });
            setLocal(state?.productId, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.rest:
            arr = arr.map((item:any) => {
                return action.key === item.key ? {
                    ...state.defaultsetting,
                    key: `${item.key}`,
                    name: `${item?.name}`
                }: item;
            });
            setLocal(state?.productId, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.checkedKey:
        return {
            ...state,
            checkedKey: action?.checkedKey
        };
        case Type.checkedProFile:
        return {
            ...state,
            checkedProFile: action?.checkedProFile
        }
        case Type.setHongName:        
            arr = arr.map((item:any) => {
                return {
                    ...item,
                    hongsetting: state?.checkedProFile === item?.key ? action.hongName : item.hongsetting,
                }
            });
            setLocal(state?.productId, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.setTySetting:        
            arr = arr.map((item:any) => {
                return {
                    ...item,
                    tysetting: state?.checkedProFile === item?.key && action?.tysetting ? 
                     JSON.parse(JSON.stringify(action.tysetting)) : item?.tysetting,
                }
            });
            setLocal(state?.productId, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.changeKeyRect:        
            arr = arr.map((item:any) => {
                return {
                    ...item,
                    key_Rect: state?.checkedProFile === item?.key && action?.keyRect ? 
                     JSON.parse(JSON.stringify(action.keyRect)) : item.key_Rect,
                }
            });
            setLocal(state?.productId, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        default:
        return state;
    }
};

export default settingStore;