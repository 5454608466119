import { keys108, keys104, keys98 } from './defautKeys.ts';

// 当前已经支持支持的产品信息
type defaultkeyType = {
    vendorId: number,
    productId: number,
    reqLen: number,
    Keyboard: any
};
const message = [
    {
        vendorId: 1389,
        productId: 49271, //Wireless Receiver (Z108 无线\Z104 无线)
        reqLen: 32,
        tysetting: [
            '00','00','63','02','00','01','ff','00',
            '00','00','00','00','00','00','00','78',
            '00','58','02','78','00','58','02','58',
            '02','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
        ],
        Keyboard: keys104,
    },
    {
        vendorId: 1389,
        productId: 49288,//Gaming Keyboard
        reqLen: 64,
        tysetting: [
            '00','00','63','02','00','01','ff','00',
            '00','00','00','00','00','00','00','78',
            '00','58','02','78','00','58','02','58',
            '02','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
        ],
        Keyboard: keys98,
    },
    {
        vendorId: 10473,
        productId: 12410, //Z108 有线
        reqLen: 64,
        tysetting: [
            '00','00','63','02','00','01','ff','00',
            '00','00','00','00','00','00','00','78',
            '00','58','02','78','00','58','02','58',
            '02','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
        ],
        Keyboard: keys108,
    },
    {
        vendorId: 10473,
        productId: 12312, //Z104 有线
        reqLen: 64,
        tysetting: [
            '00','00','63','02','00','01','ff','00',
            '00','00','00','00','00','00','00','78',
            '00','58','02','78','00','58','02','58',
            '02','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
            '00','00','00','00','00','00','00','00',
        ],
        Keyboard: keys104,
    }

];

export default {
    message,
    getByName(arr:string[] = ["vendorId", "productId"]) {
        return message.map((item:defaultkeyType) => {
            const param = {};
            arr.forEach((str:string) => {
                param[str] = item[str] ||'';
            })
            return param;

        })
    },
    getRequstDataLen(productId) {
        return message.find((item) => item?.productId === productId)?.reqLen || 64;
    },
    getTysetting(productId) {
        return message.find((item) => item?.productId === productId)?.tysetting || 64;
    },
    getKeyBoard(productId) {
        return message.find((item) => item?.productId === productId)?.Keyboard;
    }
}